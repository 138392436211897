import { createSlice } from '@reduxjs/toolkit';

const filterUGCSlice = createSlice({
    name: 'filterUGC',
    initialState: {
        isFilterOn: false,
        sortValue: 'newest',
        searchfield: '',
        pageno: 1,
        reduxhasmore: true,
        filteredData : [],
        filterTrigger: 1,
    },
    reducers: {
        setFilterON: (state, action) => {
            state.isFilterOn = action.payload;
        },
        setFilterValue: (state, action) => {
            const { sortValue, searchfield, pageno, reduxhasmore, filterTrigger } = action.payload;
            if (sortValue !== undefined) state.sortValue = sortValue;
            if (searchfield !== undefined) state.searchfield = searchfield;
            if (pageno !== undefined) state.pageno = pageno;
            if (reduxhasmore !== undefined) state.reduxhasmore = reduxhasmore;
            if (filterTrigger !== undefined) state.filterTrigger = filterTrigger;
        },
        setFilteredData: (state, action) => {
            state.filteredData = action.payload; 
        },
        updateItemStats: (state, action) => {
            const { id, likeCount, viewsCount, shareCount } = action.payload;
            const item = state.filteredData.find(item => item.id === id);
            if (item) {
                if (likeCount !== undefined) item.likeCount = likeCount;
                if (viewsCount !== undefined) item.viewsCount = viewsCount;
                if (shareCount !== undefined) item.shareCount = shareCount;
            }
        },
    }
});

export const { setFilterON, setFilterValue ,setFilteredData, updateItemStats} = filterUGCSlice.actions;
export default filterUGCSlice.reducer;
