import { createSlice } from '@reduxjs/toolkit';

const ugcSlice = createSlice({
  name: 'ugcSlice',
  initialState: {
    data: null,
    status: 'idle',
    ugcTray : [],
    likedVid : [],
  },
  reducers: {
    setUgcData: (state, action) => {
      state.data = action.payload;
    },
    clearUgcData: (state) => {
      state.data = null;
    },
    setUgcTray: (state , action) =>{
      state.ugcTray = action.payload;
    },
    setLikeVid : (state, action) =>{
      state.likedVid = action.payload;
    }

  },
});

export const { setUgcData, clearUgcData ,setUgcTray, setLikeVid } = ugcSlice.actions;
export default ugcSlice.reducer;