import { createSlice } from '@reduxjs/toolkit';



const ugcSlice = createSlice({
    name: 'ugcHome',
    initialState: {
      videoId: null,
      views: 0,
      likes: 0,
      share:0,
      likedVideos: [], // List of liked video IDs
      vidliked:false,
      viddisliked:false,
      fromHome:false,
      homeFeed:[]
    },
    reducers: {
      setUgcHome: (state, action) => {
        const { videoId, views, likes, share } = action.payload;
        state.videoId = videoId;
        state.views = views;
        state.likes = likes;
        state.share = share;
      },
      setUgcLikes: (state, action) => {
        const likedVideos = action.payload;
        state.likedVideos = likedVideos; // Updating liked videos
      },
      setvidLiked: (state, action) => {
        state.vidliked = action.payload;
        },
        setviddisliked: (state, action) => {
          state.viddisliked = action.payload;
        },
        setFromHome: (state, action) => {
            state.fromHome = action.payload;
        },
        setHomeFeed: (state , action) =>{
          state.homeFeed = action.payload;
        },
    },
    
  });
  
  // Export actions and reducer
  export const { setUgcHome, setUgcLikes, setvidLiked, setviddisliked ,setFromHome, setHomeFeed   } = ugcSlice.actions;
  export default ugcSlice.reducer;